<template>
  <Header />
  <p class="q-mt-xl text-error">Error</p>
  <p class="text-number">404</p>
  <p class="page-container text-mail">
    {{ $t("General.not-found") }}
    <span class="text-weight-bold">omniwallet@omniwallet.net</span>
  </p>
  <Footer />
</template>

<script>
import { defineAsyncComponent } from "vue";

export default {
  components: {
    Header: defineAsyncComponent(() =>
      import("../../shared/components/Header.vue")
    ),
    Footer: defineAsyncComponent(() =>
      import("../../shared/components/Footer.vue")
    ),
  },
};
</script>

<style lang="scss" scoped>
.text-error {
  font-size: 30px;
}

.text-number {
  font-size: 80px;
}

.text-mail {
  font-size: 15px;
  padding-right: 10%;
  padding-left: 10%;
}
</style>
